/*
 * @Author: luxiangqiang
 * @Date: 2023-02-02 16:47:58
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-02-02 16:53:39
 */
import { onMounted, onUnmounted, ref } from 'vue';

export default function () {
  const isShowTop = ref(false);

  const onScroll = () => {
    if (document.documentElement.scrollTop === 0) {
      isShowTop.value = false;
    } else {
      isShowTop.value = true;
    }
  };

  onMounted(() => {
    window.addEventListener('scroll', onScroll);
  });
  onUnmounted(() => {
    window.removeEventListener('scroll', onScroll);
  });

  return isShowTop;
}
