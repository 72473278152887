<!--
 * @Author: luxiangqiang
 * @Date: 2023-02-08 16:46:11
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-05-30 16:32:57
-->
<template>
  <section class="suspended">
    <div class="suspended-box" @click="onConsultClick">
      <img :src="listenIcon" alt="全球雇佣" />
      <span>{{ $t('联系专家') }}</span>
    </div>
    <div class="suspended-box online-service" :class="{ bar: isShowTop }" @mouseover="onCsHover" @mouseout="onCsOut">
      <img :src="onlineServiceIcon" alt="全球雇佣" />
      <span>{{ $t('微信客服') }}</span>
    </div>
    <div class="qr-code" :class="{ top: isShowTop }" :style="{ display: isShowQrCode ? 'flex' : 'none' }">
      <div class="text">
        <div>{{ $t('添加企业微信') }}</div>
        <div>
          {{ $t('为您提供') }}<span>{{ $t('1对1') }}</span
          >{{ $t('的系统解答') }}
        </div>
      </div>
      <img src="https://jkqj-assets-beijing.oss-cn-beijing.aliyuncs.com/dghire/qrcode.jpg" alt="qrCode" />
    </div>
    <div class="go-top" @click="handlerGoTop" v-if="isShowTop">
      <img :src="goTopIcon" alt="goTopIcon" />
      <span>{{ $t('回到顶部') }}</span>
    </div>
  </section>
</template>

<script setup lang="ts">
import onlineServiceIcon from '@/assets/icons/online-service.svg';
import listenIcon from '@/assets/icons/listen.svg';
import goTopIcon from '@/assets/icons/go-top.svg';
import useScrollTop from '@/composables/use-scroll-top';
import { getUserBrowseTrack } from '~~/common/utils';

const isShowTop = useScrollTop();

function onConsultClick() {
  useLeadsInfo('首页悬浮-服务咨询', 'serviceConsulting');
}

function handlerGoTop() {
  document.documentElement.scrollTop = 0;
}

const isShowQrCode = ref(false);
const qrCodeShowTiming = ref(0);

function logQrCodeShow() {
  window.clearTimeout(qrCodeShowTiming.value);
  if (!isShowQrCode.value) {
    return;
  }
  const track = getUserBrowseTrack('二维码') || '未知';
  const referer = document.referrer;
  const url = window.location.href;
  fetch('/api/leads/qrcode', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ track, referer, url }),
  });
}

function onCsHover() {
  isShowQrCode.value = true;
  window.clearTimeout(qrCodeShowTiming.value);
  qrCodeShowTiming.value = window.setTimeout(logQrCodeShow, 3000);
}
function onCsOut() {
  window.clearTimeout(qrCodeShowTiming.value);
  isShowQrCode.value = false;
}
</script>

<style scoped lang="less">
.mobile-recommend {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: right 1s;
  right: 10px;
  bottom: 360px;
  cursor: pointer;

  .text {
    margin-top: 2px;
    padding: 4px 6px;
    background: linear-gradient(155.71deg, #fff3df 1.51%, #fff3d8 45.14%, #ffedbf 84.45%);
    border: 1px solid #ff7c81;
    border-radius: 12px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #ff353d;
  }

  img {
    width: 35px;
    height: 35px;
    animation: jitter 1s 2s infinite;
  }
}

.hidden {
  transition: right 1s;
  right: -35px;
}

.suspended {
  width: 72px;
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  right: 0px;
  bottom: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 10px;

  &-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    max-width: 58px;


    span {
      margin-top: 7px;
      // white-space: nowrap;
      color: #4e5969;
      font-size: 13px;
      line-height: 18px;
      min-width: 52px;
      text-align: center;
    }

    img {
      width: 30px;
    }
  }

  &-box:first-child {
    border-bottom: 1px solid #e9ebf1;
    padding-bottom: 14px;
  }

  .bar {
    border-bottom: 1px solid #e9ebf1;
    margin-bottom: 10px;
  }

  .go-top {
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      white-space: nowrap;
      font-size: 13px;
      color: #4e5969;
      margin-top: 2px;
    }
  }
}

.qr-code {
  box-sizing: border-box;
  padding: 18px 12px;
  text-align: center;
  height: 180px;
  position: fixed;
  right: 70px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #ffffff 0%, #e6edff 100%);
  border: 1px solid #d8e4ff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(2px);
  border-radius: 12px;
  display: none;

  .text {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4e5969;
  }

  span {
    color: #165dff;
  }

  img {
    width: 100px;
    height: 100px;
    border-radius: 2px;
    margin-top: 8px;
  }
}

.top {
  bottom: 140px;
}

.pc-recommend {
  position: fixed;
  right: 20px;
  bottom: 310px;
  cursor: pointer;
  z-index: 999;

  img {
    width: 72px;
  }
}

footer {
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 0;
  height: 56px;
  background: #165dff;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  .footer-left {
    width: 50%;
    font-size: 16px;
    line-height: 18px;
    color: #1d2129;
    background: #eaf0ff;
  }

  .footer-right {
    width: 50%;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    background: #165dff;
  }
}

@media screen and (min-width: 800px) {
  .mobile-recommend,
  footer {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .pc-recommend,
  .go-top,
  .suspended {
    display: none !important;
  }
}
</style>
