<template>
  <div class="footer-banner">
    <div v-if="showBanner" class="banner">
      <div class="banner__title">{{ $t('开启你的全球雇佣之旅') }}</div>
      <div class="banner__subtitle">Start your globalization journey</div>
      <div class="banner__button" @click="onContactClick">{{ $t('联系我们') }}</div>
    </div>
    <div class="footer footer-pc">
      <div class="footer__content">
        <div class="footer__content-main">
          <div class="footer__left">
            <div class="footer__title">
              <img src="@/assets/images/logo-white.svg" alt="全球雇佣" />
              <!-- <span>{{ $t('一站式') }}{{ $t('全球雇佣') }}{{ $t('平台') }}</span> -->
            </div>
            <div class="footer__medias">
              <nuxt-link
                to="https://www.zhihu.com/org/dghire/answers"
                target="_blank"
                class="footer_media media-zhihu"
              />
              <nuxt-link
                to="https://author.baidu.com/home/1754058193738200"
                target="_blank"
                class="footer_media media-baijiahao"
              />
              <nuxt-link class="footer_media media-weixin" @click="onQrcodeClick" ref="weixin">
                <div class="weixin-qrcode" v-show="isQrcodeShow">
                  <h6>{{ $t('微信公众号') }}</h6>
                  <img src="https://jkqj-assets-beijing.oss-cn-beijing.aliyuncs.com/dghire/wx-public-account.jpg" />
                </div>
              </nuxt-link>
            </div>
            <a-select class="a_select" v-model="locale">
              <template #prefix>
                <img :src="langIcon" alt="lang" />
              </template>
              <a-option value="zh-CN">{{ $t('中文') }}</a-option>
              <a-option value="en-US">{{ $t('英文') }}</a-option>
            </a-select>
          </div>
          <div class="footer__right">
            <div class="menus">
              <div class="menus__col" v-for="menu in showMenus" :key="menu.name">
                <div class="menus__title">{{ $t(menu.title) }}</div>
                <div class="menus__item" v-for="item in menu.children" :key="item.name">
                  <nuxt-link :to="item.url">{{ $t(item.title) }}</nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="locale === 'zh-CN'" class="footer__links">
          <span>{{ $t('友情链接') }}:</span>
          <a v-for="link in footLinks" target="_Blank" :href="link.link" :key="link.link">{{ link.name }}</a>
        </div>
      </div>
      <div class="footer__split"></div>
      <div class="footer__copyright">
        <span>© 2023 北京招招才科技有限公司 All Right Reserved</span>
        <a target="_Blank" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021038364号-27</a>
      </div>
    </div>
    <div class="footer footer-mobile">
      <div class="footer__title">
        <img src="@/assets/images/logo-white.svg" alt="全球雇佣" />
        <span>一站式全球雇佣平台</span>
        <div class="footer__medias">
          <nuxt-link to="https://www.zhihu.com/org/dghire/answers" target="_blank" class="footer_media media-zhihu" />
          <nuxt-link
            to="https://author.baidu.com/home/1754058193738200"
            target="_blank"
            class="footer_media media-baijiahao"
          />
          <nuxt-link class="footer_media media-weixin" @click="onQrcodeClick" ref="weixin">
            <div class="weixin-qrcode" v-show="isQrcodeShow">
              <h6>微信公众号</h6>
              <img src="https://jkqj-assets-beijing.oss-cn-beijing.aliyuncs.com/dghire/wx-public-account.jpg" />
            </div>
          </nuxt-link>
        </div>
      </div>
      <!-- <div class="footer__links">
        <div>{{ $t('友情链接') }}:</div>
        <div class="links">
          <a v-for="link in footLinks" target="_Blank" :href="link.link" :key="link.link">{{ link.name }}</a>
        </div>
      </div> -->
      <div class="footer__copyright">
        <span>© 2023 北京招招才科技有限公司 All Right Reserved</span>
        <a target="_Blank" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021038364号-27</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import menus from '@/common/menu';
import { getSubDomain } from '~~/common/utils';
import subSites from '@/assets/contents/sub-sites.json';
import langIcon from '@/assets/icons/lang-white.svg';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
const showMenus = menus.filter(menu => !!menu.children?.length && menu.name !== 'showcase');
const route = useRoute();

const excludeBannerPath = ['/resource/partner-program', '/about/partner-program'];
const showBanner = computed(() => !excludeBannerPath.includes(route.path));

function onContactClick() {
  useLeadsInfo('底部banner-联系我们', 'contactUs');
}

const isQrcodeShow = ref(false);
function onQrcodeClick() {
  isQrcodeShow.value = true;
}

function onOutsideClick(e: Event) {
  if (!(e.target as HTMLElement).classList.contains('media-weixin')) {
    isQrcodeShow.value = false;
  }
}
onMounted(() => {
  document.addEventListener('click', onOutsideClick);
  return () => {
    document.removeEventListener('click', onOutsideClick);
  };
});

const baseLinks = [
  {
    link: 'https://www.tabcut.com',
    name: '特看Tiktok数据分析平台',
  },
  {
    link: 'https://www.dianshangren.cn',
    name: '电商人才网',
  },
  {
    link: 'https://www.googlesz.cn',
    name: '谷歌优化推广',
  },
  {
    link: 'https://123.dtkj.net/sid/5009.html',
    name: '灯塔跨境导航',
  },
  {
    link: 'https://www.xinlinggong.top/',
    name: '灵活用工平台',
  },
];

const subSitesList = Object.keys(subSites).map(key => ({
  link: `http://${key}.dghire.com`,
  name: subSites[key as keyof typeof subSites],
}));
subSitesList.unshift({
  link: 'https://www.dghire.com',
  name: '全国',
});

const footLinks = computed(() => {
  let host;
  if (process.client) {
    host = window.location.host;
  } else {
    host = useRequestHeaders().host;
  }
  const subDomain = getSubDomain(host || '');
  if (Object.keys(subSites).includes(subDomain)) {
    return subSitesList.filter(s => s.name !== subSites[subDomain as keyof typeof subSites]);
  }
  return baseLinks;
});
</script>
<style lang="less" scoped>
.footer-banner {
  &.has-consult {
    padding-bottom: 56px;
  }
}

.footer__title {
  display: flex;
  align-items: center;

  color img {
    width: 132px;
    height: 32px;
  }

  font-size: 14px;
  line-height: 20px;

  span {
    margin-left: 21px;
    color: #fff;
    opacity: 0.8;
    flex-shrink: 0;
  }
}

.footer__links {
  font-size: 12px;
  line-height: 22px;
  color: #c9cdd4;
  opacity: 0.8;

  a {
    &,
    &:hover,
    &:visited {
      color: #c9cdd4;
      text-decoration: none;
    }
  }
}

.footer__copyright {
  color: #d3d3d3;
  opacity: 0.4;

  a {
    text-decoration: none;
    color: #d3d3d3;
    cursor: pointer;

    &,
    &:visited {
      color: #d3d3d3;
    }
  }
}

.footer__medias {
  display: flex;
  position: relative;

  & > a {
    display: block;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 36px;

    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  .media-zhihu {
    background-image: url('@/assets/icons/zhihu-icon.svg');
    background-repeat: no-repeat;
    background-position: 7px center;
  }

  .media-baijiahao {
    background-image: url('@/assets/icons/baijiahao-icon.jpg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .media-weixin {
    background-image: url('@/assets/icons/weixin-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    cursor: pointer;

    .weixin-qrcode {
      text-align: center;
      position: absolute;
      width: 132px;
      padding: 32px 32px 0 32px;
      top: 45px;
      left: -48px;
      z-index: 1;
      background: linear-gradient(180deg, #ffffff 0%, #e6edff 100%);
      border: 1px solid #d8e4ff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(2px);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 0 12px;
      // box-sizing: border-box;

      h6 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #1d2129;
        margin: 0;
      }

      img {
        width: 100px;
        height: 100px;
        margin-top: 4px;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  :deep(.a_select) {
    margin-top: 24px;
    width: fit-content;
    max-width: 130px;
    margin-right: 10px;
    background: transparent;
    color: #fff;
    padding-left: 0;
    .arco-select-view-suffix {
      color: #fff;
    }
  }
  .footer-banner {
    color: #fff;
    min-width: 1344px;
  }

  .banner {
    height: 241px;
    background: url('https://video.reta-inc.com/image/default/67AE9ABAEE2D4B578DE25B1247A94462-6-2.jpeg') #165dff;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__title {
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      opacity: 0.5;
    }

    &__button {
      cursor: pointer;
      margin-top: 24px;
      width: 124px;
      height: 44px;
      background: #fc7a58;
      border-radius: 6px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
    }
  }

  .footer-mobile {
    display: none;
  }

  .footer {
    background: #11295f;

    &__content,
    &__split {
      width: 1280px;
      margin: 0 auto;
    }

    &__content {
      padding: 60px 0 16px;
      box-sizing: border-box;
    }

    &__content-main {
      display: flex;
      // align-items: center;
      justify-content: space-between;
    }

    &__medias {
      margin-top: 32px;
    }

    &__split {
      height: 1px;
      background: #fff;
      opacity: 0.2;
    }

    &__links {
      a {
        margin-left: 8px;
      }
    }

    &__copyright {
      height: 60px;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      opacity: 0.4;

      a {
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;
        margin-left: 24px;

        &,
        &:visited {
          color: #ffffff;
        }
      }

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .menus {
    display: flex;

    &__col {
      // max-height: 200px;
      // overflow: auto;

      &:not(:first-child) {
        margin-left: 64px;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 24px;
    }

    &__item {
      margin-top: 12px;
      font-size: 14px;
      line-height: 20px;

      a,
      a:visited {
        text-decoration: none;
        color: #c9cdd4;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 800px) {
  .banner {
    padding: 10px 0;
    color: #fff;
    background: url('https://video.reta-inc.com/image/default/67AE9ABAEE2D4B578DE25B1247A94462-6-2.jpeg') #165dff;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 50px;
    }

    &__subtitle {
      // font-size: 16px;
      line-height: 24px;
      // margin-top: 4px;
      opacity: 0.5;
    }

    &__button {
      cursor: pointer;
      margin-top: 8px;
      width: 80px;
      height: 32px;
      background: #fc7a58;
      border-radius: 6px;
      font-weight: 600;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
    }
  }

  .footer-pc {
    display: none;
  }

  .footer-mobile {
    background: #11295f;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-bottom: 50px;

    .footer__title {
      margin-top: 24px;
    }

    .footer__medias {
      margin-left: 12px;

      & > a {
        width: 20px;
        height: 20px;
        background-size: 60%;

        &:not(:first-child) {
          margin-left: 4px;
        }
      }

      .media-zhihu {
        background-position: center;
      }

      .media-weixin {
        .weixin-qrcode {
          position: absolute;
          left: auto;
          right: 0;
        }
      }
    }

    .footer__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 18px;
      line-height: 12px;

      .links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 6px;
        margin: 10px 19px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        a {
          white-space: nowrap;
          margin: 12px 10px;
          margin: 0 0 0 6px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .footer__copyright {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 16px;

    span,
    a {
      display: block;
      text-align: center;
    }

    a {
      margin-top: 2px;
    }
  }
}
</style>
