<!--
 * @Author: luxiangqiang
 * @Date: 2023-01-28 14:26:08
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-06-02 17:47:06
-->
<template>
  <div class="page">
    <!-- <ActivityBar v-if="showActivityBar" /> -->
    <HeaderMenu />
    <div class="main-content">
      <slot></slot>
    </div>
    <FooterBanner v-if="showFooterBanner" />
    <div class="loading-mask" v-show="loading">
      <a-spin />
    </div>
    <CustomerService />
    <!-- <div class="help_tip" v-if="closeFlag" id="tip">
      <div class="help_tip_title">Hi，我是出海小助手 </div>
      <div class="help_tip_desc">欢迎您随时咨询全球用工问题</div>
      <img @click="handlerClose" :src="close" alt="close" />
      <div class="triangle"></div>
    </div> -->
  </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import CustomerService from '~~/components/CustomerService.vue';
import { isSpider } from '@/common/utils';
import close from '@/assets/icons/close2.svg';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

const route = useRoute();
// const msg = ref('');
// const timer = ref();
// const scrollTitleTimer = ref();

const showFooterBanner = computed(() => route.path !== '/activity');
const closeFlag = ref(true);

const handlerClose = () => {
  closeFlag.value = false;
};

const loading = ref(false);
const app = useNuxtApp();
app.hook('page:start', () => {
  loading.value = true;
  // msg.value = '';
});
app.hook('page:finish', () => {
  loading.value = false;
  checkRecordTime();
  // clearTimeout(timer.value);
  // if (scrollTitleTimer.value) {
  //   clearTimeout(scrollTitleTimer.value);
  //   scrollTitleTimer.value = undefined;
  // }
  // scrollTitleTimer.value = setTimeout(() => {
  //   setAlternateTitle();
  // }, 5000);
});

function checkRecordTime() {
  const recordTimeKey = '__record_time__';
  const recordTime = localStorage.getItem(recordTimeKey);
  if (!recordTime) {
    localStorage.setItem(recordTimeKey, `${Date.now()}`);
    return;
  }
  if (Date.now() - Number(recordTime) > 86400000) {
    localStorage.setItem(recordTimeKey, `${Date.now()}`);
    localStorage.setItem('__record_list__', JSON.stringify([]));
  }
}

// function setAlternateTitle() {
//   if (!document.querySelector('title')) {
//     return;
//   }

//   msg.value = document.querySelector('title')!.innerText;
//   scrollMSG();
// }

// function scrollMSG() {
//   if (document.title === '一站式全球用工与薪酬管理平台/服务中国企业出海') {
//     document.title = msg.value;
//   } else {
//     document.title = '一站式全球用工与薪酬管理平台/服务中国企业出海';
//   }
//   timer.value = window.setTimeout(() => {
//     scrollMSG();
//   }, 500);
// }

onMounted(() => {
  if (isSpider() || process.env.NODE_ENV !== 'production') {
    return;
  }
  const referer = document.referrer;
  const url = window.location.href;
  const screen = {
    width: document.documentElement.clientWidth || window.innerWidth,
    height: document.documentElement.clientHeight || window.innerHeight,
  };
  fetch('/api/user/check', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      referer,
      url,
      screen,
    }),
  });
});

provide('global-loading', loading);
</script>
<style lang="less">
.page {
  min-height: 100%;
  min-width: 1344px;

  .main-content {
    min-height: calc(100% - 70px - 591px);
  }

  .help_tip {
    position: fixed;
    bottom: 40px;
    right: 60px;
    width: 234px;
    height: 76px;
    background: #ffffff;
    border: 1px solid #DCE7FF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 14px 20px;

    // @media screen and (max-width: 800px) {
    //   display: none;
    // }

    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1D2129;
    }

    &_desc {
      font-size: 14px;
      line-height: 22px;
      color: #4E5969;
      margin-top: 4px;
    }

    img {
      position: absolute;
      right: 8px;
      top: 15px;
      cursor: pointer;
    }

    &::after {
      content: '';
      display: block;
      margin-left: 211px;
      margin-top: -14px;
      width: 0;
      height: 0;
      top: -10px;
      border: 7px solid transparent;
      border-left: 7px solid #fff;
    }

    .triangle {
      margin-left: 213px;
      margin-top: -30px;
      width: 0;
      height: 0;
      top: -10px;
      border: 7px solid transparent;
      border-left: 7px solid #DCE7FF;
    }
  }
}

.loading-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 800px) {
  .page {
    min-width: 0;
    .help_tip {
      display: none;
    }
  }
}
</style>
